<template>
  <el-container class="wrapper">
    <el-col class="wrapper-body">
      <el-row class="wrapper-body-content">
        <el-col :xs="24" :md="9" :xl="7" class="side-content">
          <el-row type="flex" class="side-content-container">
            <div class="side-content-container__image">
              <div class="side-content-container__image--content">
                <img src="../../../assets/logo.png" alt="gefyra">
              </div>
            </div>
            <div class="hidden-sm-and-down side-content-container-headerwrapper">
              <div class="side-content-container-headerwrapper__header">
                <div class="side-content-container-headerwrapper__header--text">
                  <span>Welcome to NRCP Inventory Management System</span>
                </div>
              </div>
            </div>
            <div class="hidden-sm-and-down side-content-container-footer">
              <div class="side-content-container-footer--copyright">
                <span>&copy; 2022 NRCP</span>
              </div>
              <!-- <div class="side-content-container-footer--contact">
                <span>Contact</span>
              </div> -->
            </div>
          </el-row>
        </el-col>
        <el-col :xs="24" :md="15" :xl="17" class="main-content">
          <el-row type="flex" class="main-content-container">
            <div class="main-content-container-header">
              <div class="main-content-container-header-content">
                <div class="main-content-container-header-content--header">
                  <span>Sign In</span>
                </div>
                <div class="main-content-container-header-content--text">
                  <span>Enter your username and password</span>
                </div>
              </div>
            </div>
            <div class="main-content-container-form">
              <div class="main-content-container-form--input">
                <input type="text" class="user-input" placeholder="Username" v-model="login.username">
              </div>
              <div class="validator" style="height: 2rem"><span v-if="!handleUsername">Username field is required!</span></div>
              <div class="main-content-container-form--input">
                <input type="password" class="user-input" placeholder="Password" v-model="login.password">
              </div>
              <div class="validator" style="height: 2rem"><span v-if="!handlePassword">Password field is required!</span></div>
              <div class="main-content-container-form-footer">
                <div class="main-content-container-form-footer--text">
                  <span>Can't Login? Contact your Administrator</span>
                </div>
                <div class="main-content-container-form-footer--signin">
                  <button @click="handleLogin" :disabled="!handleSubmit" v-if="!handleSubmit" style="color: #D3D3D3; background-color: #f75990">Sign in</button>
                  <button @click="handleLogin" :disabled="!handleSubmit" v-if="handleSubmit">Sign in</button>
                </div>
              </div>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-container>
</template>

<style lang="scss" scoped>

.validator {
  text-align: left;
  font-size: 13px;
  color: #E00B0B;
}

.wrapper {
  overflow: hidden;
  position: absolute;
  background: #eeecff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &-body {
    &-content {
      height: 100%;
    }
  }

  .side-content {
    color: #fff;
    background: #0e0c28;

    &-container {
      align-items: center;
      justify-content: center;

      &__image {
        padding: 10px 10px 0 5px;

        img {
          height: 70px;
        }
      }
    }
  }

  .main-content {
    height: 100%;
    margin-top: -40px;

    &-container {
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-header {
        margin-bottom: 40px;

        &-content {
          text-align: center;

          &--header {
            font-size: 1.75rem;
            font-weight: 500;
            color: black;
          }

          &--text {
            font-size: 0.9rem;
            font-weight: 500;
            color: #b2b2c3;
          }
        }
      }

      &-form {
        width: 90%;
        max-width: 450px;

        &--input {
          display: flex;
          justify-content: center;

          input {
            width: 100%;
            height: 1.25rem;
            padding: 15px;
            border: none;
            resize: vertical;
            outline: none;
            border: 10%;
            font-family: Poppins, Helvetica, sans-serif !important;
          }

          input:focus {
            background-color: #f3f6f9;
          }

          input::placeholder {
            color: #b2b2c3;
          }
        }

        &-footer {
          display: flex;
          width: 100%;
          align-items: center;

          &--text {
            text-align: left;
            margin-right: auto;
            font-size: 0.75rem;
          }

          &--signin {
            margin-left: auto;
            width: 30%;
            text-align: right;

            button {
              font-family: Poppins, Helvetica, sans-serif !important;
              color: white;
              width: 100px;
              height: 45px;
              padding: 0px;
              text-decoration: none;
              font-size: 16px;
              cursor: pointer;
              outline: none;
              background-color: #409eff;
              border: none;
            }

            button:hover {
              background-color: #2592ff;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .side-content {
    height: 100%;

    &-container {
      height: 100%;
      flex-direction: column;
      justify-content: space-between !important;
      align-items: flex-start !important;
      padding: 1.75rem;

      &__image {
        padding: 0 !important;
      }

      &-headerwrapper {
        &__header {
          font-size: 1.75rem;
          font-weight: 500;
        }
      }

      &-footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
</style>

<script>
// services
import SignInService from '@/services/v1/SignIn'

export default {
  data () {
    return {
      login: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    // handlers
    async handleLogin () {
      try {
        const siService = new SignInService()
        const result = await siService.post(this.login)
        if (result.data.access_token) {
          if (result.data.permissions.length === 0) {
            this.$notify.error({
              title: 'Error',
              message: 'You are not authorized to access the system!'
            })
            return
          }

          localStorage.setItem('user', JSON.stringify(result.data))
          this.$router.push({ name: 'dashboard' })
        }
      } catch (error) {
        this.$Error(error.response)
      }
    }
  },
  computed: {
    handleUsername () {
      return !!this.login.username
    },
    handlePassword () {
      return !!this.login.password
    },
    handleSubmit () {
      return this.handleLogin && this.handlePassword
    }
  }
}
</script>
